 /* styles.css */
 .MuiButton-containedPrimary.boutonAjouter {
     /* color: black;
     background-color: rgba(237, 215, 73, 0.998); */

     /* align-items: center; */
     padding-left: 4px;
     padding-right: 4px;

 }



 .center {
     display: flex;
     justify-content: center;
 }

 .hoverLimite {
     width: 40px;
 }

 img {
     width: 25px;
     cursor: pointer;

 }


 /* .MuiButton-containedPrimary.MuiButton-containedPrimary.boutonAjouter.focused {
     color:
         brown;
     background-color: brown
         /* Couleur de fond lorsque focus */


 input {
     border: none;
     /* Supprime toutes les bordures par défaut */
     border-bottom: 1px solid #000;
 }


 /* Appliquer une largeur maximale de 60px aux cellules du tableau */
 .table .MuiTableCell-root {
     /* max-width: 80px; */
     overflow: hidden;
     /* Optionnel : pour masquer le contenu dépassant la largeur maximale */
     white-space: nowrap;
     /* Optionnel : pour empêcher le texte de passer à la ligne */
     text-overflow: ellipsis;
     /* Optionnel : pour ajouter des points de suspension (...) au texte coupé */
 }

 .table .cellLimitee {
     width: 10vw;
     min-width: 10vw;
     max-width: 12vw;
     overflow: hidden;
     /* Optionnel : pour masquer le contenu dépassant la largeur maximale */
     white-space: nowrap;
     /* Optionnel : pour empêcher le texte de passer à la ligne */
     text-overflow: ellipsis;
     /* Optionnel : pour ajouter des points de suspension (...) au texte coupé */
 }

 .table .cellLimiteeMobile {
     width: 100px;
     max-width: 100px;

     overflow: hidden;
     /* Optionnel : pour masquer le contenu dépassant la largeur maximale */
     white-space: nowrap;
     /* Optionnel : pour empêcher le texte de passer à la ligne */
     text-overflow: ellipsis;
     /* Optionnel : pour ajouter des points de suspension (...) au texte coupé */
 }

 .table .cellPhone {
     width: 8vw;
     max-width: 10vw;
     overflow: hidden;
     /* Optionnel : pour masquer le contenu dépassant la largeur maximale */
     white-space: nowrap;
     /* Optionnel : pour empêcher le texte de passer à la ligne */
     text-overflow: ellipsis;
     /* Optionnel : pour ajouter des points de suspension (...) au texte coupé */
 }

 .table .cellMail {
     width: 5vw;
     max-width: 10vw;
     overflow: hidden;
     /* Optionnel : pour masquer le contenu dépassant la largeur maximale */
     white-space: nowrap;
     /* Optionnel : pour empêcher le texte de passer à la ligne */
     text-overflow: ellipsis;
     /* Optionnel : pour ajouter des points de suspension (...) au texte coupé */
 }





 .parent-div {
     display: flex;
     width: 50vw;

 }

 /* .left-div {
     width: 15%;
    

     flex: 1;
     margin-left: 20px;
     border-left: 0.7px solid rgb(201, 199, 199);
     padding-left: 1.2%; 
display: block;
     height: 100%; } */


 .left-div {
     width: 15%;
     /*   max-width: 20%; */
     position: fixed;
     flex: 1;
     display: block;
     height: 100%;
     margin-left: 66vw;
     border-left: 0.7px solid rgb(201, 199, 199);
     padding-left: 1.2%;
     /* Le premier élément prend autant d'espace que possible à gauche */
 }



 /* Lorsque la largeur de la fenêtre est inférieure ou égale à 768px, l'élément est caché */
 @media (max-width: 1207px) {
     .left-div {
         display: none;
     }
 }


 .on {
     background-color: #2ecc71;
     /* Couleur quand le bouton est activé (ON) */
 }

 .off {
     background-color: #e74c3c;
     /* Couleur quand le bouton est désactivé (OFF) */
 }

 .enCours-bgd {
     background-color: #D5D5D5;
 }

 .rescentCli {
     background-color: #dece93;

 }

 .custom-row-class {
     border-bottom: 0.8px solid rgba(209, 206, 206, 0.525);

     padding-left: 100px;
     margin-left: 100px;
     ;
     margin-top: 80%;

     height: 80px;
     min-height: 80px;
     max-height: 80px;
 }

 .client-column-content {

     overflow: hidden;
     text-overflow: ellipsis;
 }

 .nom-column-content {

     width: 10px;
     min-width: 10px;
     max-width: 10px;
     height: 9vh;
     overflow: hidden;
     text-overflow: ellipsis;
 }

 .client-column-content1 {

     white-space: nowrap;

 }



 .p-datatable .p-datatable-thead>tr>th {
     height: 10vh;
     border-bottom: solid 1px #4a4c4bb4;
     font-weight: bold;
 }