 .toutStock {
     min-width: max-content;
     padding-top: 3vh;


 }



 .colonne {
     display: flex;
     flex-direction: column;
     /* Spécifie que les éléments doivent être alignés en colonne */

     /* Aligne les éléments horizontalement au centre de la colonne */
 }

 .ligne {
     position: relative;
     display: flex;
     /* Utilisez flexbox pour afficher les composants côte à côte */
     flex-wrap: wrap;

 }

 


 .contenneurP {

     position: absolute;
     margin-top: 1vh;
     /* border: 1px solid rgb(251, 246, 246); */
     height: 13vh;
     width: 8vh;
     color: rgb(255, 255, 255);
     position: relative;
     /* Position relative pour ajuster la position */
     /* transform: rotate(90deg);
    /* Incline l'élément de 70 degrés par rapport à l'horizontale */
     /* transform-origin: top left; */
     /* Point d'origine de la rotation, ajustez selon vos besoins */
     /* left: 0;
    
    top: 0;   */

 }

 .contenneurG {


     /* border: 1px solid rgb(251, 246, 246); */
     height: 24vh;
     width: 8vh;
     color: rgb(252, 252, 254);
 }

 .contenneurPr {


     position: absolute;
     margin-top: 1vh;
     /* border: 1px solid rgb(251, 246, 246); */
     width: 13vh;
     height: 8vh;
     color: rgb(255, 255, 255);
     position: relative;
 }