 .Item {
     margin-bottom: 20px;
     display: flex
 }

 .piePrincipal {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

 }