 .navbar {
   color: #146C94;
   height: 8vh;
   width: 100%;
   background-color:
     #AFD3E2;
   display: flex;
   flex-direction: row;
   position: fixed;
   z-index: 2;
   margin-Top: -10px;
   margin-Left: -10px;
   align-items: center;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif;
 }

 .select-without-border {
   outline: none !important;
   border: none !important;
   box-shadow: none !important;
 }



 .navbar .activeLink {
   color: #132043;
   /* Couleur souhaitée pour le lien actif */
 }

 .navbar .leftSide {
   flex: 50%;

   display: flex;
   align-items: center;
   /* padding-top: 0.2vw;
   padding-left: 0.2vw; */
 }

 .navbar .leftSide img {
   width: 5vw;
 }



 .navbar .rightSide {
   flex: 50%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
 }



 .navbar .rightSide button {
   background-color: transparent;
   border: none;
   color: white;
   cursor: pointer;
 }


 .navbar #open a {
   width: 70px;
   margin: 5px;
 }

 .logout-container {
   padding: 5px;
   position: relative;
   display: inline-block;
 }

 .tooltip {
   padding: 5px;

   visibility: hidden;
   background-color: #7b7b7b7c;
   color: #000000c4;
   text-align: center;
   border-radius: 10px;
   padding: 5px;
   position: absolute;
   z-index: 1;
   top: 75%;
   left: 1%;
   transform: translateX(-50%);
   opacity: 0;
   transition: opacity 0.3s;
 }

 .logout-container:hover .tooltip {
   visibility: visible;
   opacity: 1;
 }