 
.petit {
    width: 170px;
    height: 170px;
    margin-right: 20px;
    ;
}

.grand {
    width: 350px;
    height: 350px;
}

.mob{
   
    width: 280px;
    height: 280px;
}

.card {
    padding: 18px;
    background-color: rgba(244, 244, 241, 0.813);
    border: 0.5px solid rgb(202, 198, 198);
    border-radius: 10px;
    padding-left: 1vh; 

}

.containerDash {
    display: flex
}

.contRetard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



}

 
 
    .cardDashMobStk {
     
        display: flex; /* Activer Flexbox */
        align-items: center; /* Centrer verticalement */
        justify-content: center; /* Centrer horizontalement */
    
        
        margin: 0 auto; /* Pour centrer le conteneur lui-même */
    }
    .cardDashMob  {
      
  max-width:  88vw;
        align-items: center; /* Centrer verticalement */
        justify-content: center; /* Centrer horizontalement */
        
        width: 88vw;
        margin: 0 auto; /* Pour centrer le conteneur lui-même */
    }
    