.building {
    background: #f2f2f2;
    font-family: monospace;
    color: #333;
    width: 95%;
    height: 90%;
 
    padding-top: 5px;
    /* -webkit-box-shadow: 0px 0px 0 #bbb,1px 1px 0 #bbb,2px 2px 0 #bbb,3px 3px 0 #bbb,4px 4px 0 #bbb,5px 5px 0 #bbb,6px 6px 0 #bbb,7px 7px 0 #bbb,8px 8px 0 #bbb,9px 9px 0 #bbb,10px 10px 0 #bbb,11px 11px 0 #bbb,12px 12px 0 #bbb,13px 13px 0 #bbb,14px 14px 0 #bbb,15px 15px 0 #bbb,16px 16px 0 #bbb;;
       -moz-box-shadow: 0px 0px 0 #bbb,1px 1px 0 #bbb,2px 2px 0 #bbb,3px 3px 0 #bbb,4px 4px 0 #bbb,5px 5px 0 #bbb,6px 6px 0 #bbb,7px 7px 0 #bbb,8px 8px 0 #bbb,9px 9px 0 #bbb,10px 10px 0 #bbb,11px 11px 0 #bbb,12px 12px 0 #bbb,13px 13px 0 #bbb,14px 14px 0 #bbb,15px 15px 0 #bbb,16px 16px 0 #bbb;;
            box-shadow: 0px 0px 0 #bbb,1px 1px 0 #bbb,2px 2px 0 #bbb,3px 3px 0 #bbb,4px 4px 0 #bbb,5px 5px 0 #bbb,6px 6px 0 #bbb,7px 7px 0 #bbb,8px 8px 0 #bbb,9px 9px 0 #bbb,10px 10px 0 #bbb,11px 11px 0 #bbb,12px 12px 0 #bbb,13px 13px 0 #bbb,14px 14px 0 #bbb,15px 15px 0 #bbb,16px 16px 0 #bbb;; */
}
h1 {
    text-align: center;
    font-size: 1.8em;
    font-weight: bold;
}
.line {
 
    padding: 10px;
    font-size: 30px;
    font-weight: normal;
    line-height: .7em;  
    /*padding: 5px;*/
    /* cursor: crosshair; */
}
.container {
 
    padding: 10px;
    font-size: 19px;
    font-weight: normal;
    line-height: .7em;  
    /*padding: 5px;*/
    /* cursor: crosshair; */
}
.nodispo { color: #ffffff !important; background: linear-gradient(to bottom,  #d80b0bb7, #de4f4fe0)
    !important; }
.dispo { color: #ffffff !important; background: linear-gradient(to bottom, #6d8434ec,  #94b443)
     !important; }