 .navbarGauche {

     height: 100vh;
     width: 250px;
     background-color: #146C94;
     display: flex;
     flex-direction: column;
     font-size: 16px;
     z-index: 1;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;

     margin-Left: -10px;
     overflow: hidden;

 }





 /* 
.navbarGauche .link {

    margin-right: 90px;
    padding-top: 12vh;
} */

 .navbarGauche .ty {

     color: white;
     text-decoration: none;


 }


 .navbarGauche .activeLink {
     color: #ffc20cef;
     text-decoration: none;
     /* Couleur souhaitée pour le lien actif */
 }

 /* NavbarGauche.css */
 .lien {
     margin-top: 1vh;
     /* Ajoutez une marge à droite de 20px entre chaque lien */
 }

 .divLink {
     margin-left: 30px;
     margin-top: 45px;

 }