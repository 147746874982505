 .background {
     background-color: #eaeaea;
     /* margin-top: 50px; */
     border-radius: 8px;

     font-size: 25px;
     font-weight: 400;
     position: relative;
     border: 0.3px solid rgb(201, 199, 199);
     padding: 20px;
     min-width: max-content;

 }
 .colonne {
     display: flex;
     flex-direction: column;
     /* Spécifie que les éléments doivent être alignés en colonne */

     /* Aligne les éléments horizontalement au centre de la colonne */
 }

 .ligne {
     position: relative;
     display: flex;
     /* Utilisez flexbox pour afficher les composants côte à côte */
     flex-wrap: wrap;

 }

 .vert {
     text-align: center;
     background: linear-gradient(to bottom, #6d8434ec,  #94b443);
     border-color:#556629;
     border: 1px solid  ;
     border-radius: 7%;
     
 }

 .rouge {
     text-align: center;
     align-content: center;
     border-radius: 5%;
     background: linear-gradient(to bottom,  #d80b0bb7, #de4f4fe0);
     border: 1px solid  ;
     border-color: #d80a0abb;
     
 }


 .contenneurP {
     position: absolute;
     margin-top: 1vh;
 
     height: 13vh;
     width: 8vh;
     color: rgb(255, 255, 255);
     position: relative;
     /* Position relative pour ajuster la position */
     /* transform: rotate(90deg);
    /* Incline l'élément de 70 degrés par rapport à l'horizontale */
     /* transform-origin: top left; */
     /* Point d'origine de la rotation, ajustez selon vos besoins */
     /* left: 0;
    
    top: 0;   */

 }

 .contenneurG {

   
     height: 24vh;
     width: 8vh;
     color: rgb(252, 252, 254);
 }

 .contenneurPr {
 
     margin-top: 1vh;
   
     width: 13vh;
     height: 8vh;
     color: rgb(255, 255, 255);
     position: relative;
 }



 .etat h3 {
     color:#146C94;
     font-size: 22px;
 }

 .popup {
 
     min-height: 40vh;
     height: 40vh;
     max-height: 40vh;
     width: 100vw;
 
     overflow: scroll;
         /* width: 80vw;
     height: 30vh;
     position: fixed;
     background-color: #eaeaedc9;
     min-height: 30vh;
   
     overflow-y: auto;
     padding-bottom: 20px;
     /* top: 0;  
     left: 0;
      Ajout d'une position à gauche  
     right: 0; */
         /* Ajout d'une position à droite */
 }

 .resizeIcon {
     left: 0;
     position: fixed;
     cursor: pointer;
     /* margin-top: 45vh; */
     /* margin-left: 80vw; */
     /* border: 1px solid#111417; */
     /* border: 1px solid rgb(204, 199, 199); */
     z-index: 999;
     max-height: 90vh;
     font-size: small;
     overflow: scroll;
     max-width: 100vw;
     border-radius: 3%;
     /* background-color: #f5f7fbd2; */
     color: rgb(11, 9, 9);

 }

 .Icon {

     left: 0;
     border-radius: 10%;
 }



 /* Lorsque la largeur de la fenêtre est inférieure ou égale à 768px, l'élément est caché */
 /* @media (max-width: 1500px) {
     .droiteStock {
         display: none;
     }
 } */



 .containerBox {
     /* width: 100%; */
     padding: 2%;
     /* flex: 1; */


 }


 .titre {
     text-align: center;
     color: #000006d6;
     text-transform: uppercase;
     font-size: 27px;

     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
 }

 .titre1 {
     text-align: center;
     color: #4F4F63;
     text-decoration: underline;

     font-size: 20px;
     /* Transforme le texte en majuscules */
 }

 .highlight-yellow {
   
     border: 3px solid #ffbb00;
     /* Couleur de la bordure */
     background: linear-gradient(to bottom, #ffaa00, #eeb907);
     /* Couleur du dégradé */
     /* color: white; */
     /* Couleur du texte */
 }



 .flex {
     display: grid;
     grid-template-columns: 1fr 20%;
 }

 

 .gaucheStock>div>* {
     margin-bottom: 10px;
     /* Ajoutez la marge que vous souhaitez entre les éléments */
 }
 
 .zoom-outCont {
     margin-top: -50vh;
     transform: scale(0.48);
     /* ou tout autre facteur de zoom souhaité */
     transform-origin: 0 0;
     /* Point d'origine pour le zoom, ajustez si nécessaire */
     /* min-width: 100vw;
     width: 100vw; */
 }

 .zoom-outEtg {
     margin-top: -43vh;
     background-color: #dd0917d7;
     margin-left: 25vw;
     transform: scale(0.45);
     /* ou tout autre facteur de zoom souhaité */
     transform-origin: 0 0;
     /* Point d'origine pour le zoom, ajustez si nécessaire */
     min-width: max-content;
     width: 200vw;

 }

 .zoom-out {
     transform: scale(0.5);
     /* ou tout autre facteur de zoom souhaité */
     transform-origin: 0 0;
     /* Point d'origine pour le zoom, ajustez si nécessaire */
     min-width: max-content;
 
     /* background-color: #146C94; */
     
 }
 .zoom-out-cont{
    
    transform: scale(0.45);
    /* ou tout autre facteur de zoom souhaité */
    transform-origin: 0 0;
    /* Point d'origine pour le zoom, ajustez si nécessaire */
    min-width: max-content;
    /* background-color: #146C94; */
    
}

 .parentBgMobile{
    /* background-color: #cd0be747; */
    /* margin-top: 50px; */
    border-top: 1px solid #4F4F63;
   /* background-color: #b44343; */
    margin-left: 20px;
    font-size: 25px;
    font-weight: 400;
 
    /* padding: 1px; */
    width: 90vw;
    min-width: 90vw;
    
 }

 @media (max-width: 1024px) {
    .flex {
      flex-direction: column;
      padding: 10px;
    }
 
  }
  
  @media (max-width: 1200px) {
    .flex {
      flex-direction: row;
      justify-content: space-around;
    }
   
  }
  
  @media (max-width: 768px) {
    .zoom-out, .zoom-out-cont {
      zoom: 0.8; /* Un zoom automatique pour les petits écrans */
    }
    .parentBgMobile {
      margin: 0;
    }
  }