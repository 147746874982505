 .modalContainer,
 .modalContainerSupr {
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 37vw;

     border-radius: 12px;
     background-color: white;
     box-shadow: 24px;
     padding: 2%;
     overflow-y: auto;
     z-index: 1000;
     height: fit-content;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
 }

 .modalContainerSupr {

     width: 30vw;

 }

 @media screen and (max-width: 1207px) {
     .modalContainer {
         margin-top: -5vh;
         width: 80vw;
         height: 80vh;


     }

     input {
         width: 120%;
         height: 20px;
         font-size: medium;
         /* margin-top: 3px; */
         /* padding-top: 5px; */
     }

     .modalContainerSupr {

         width: 70vw;

     }
 }

 .titreEtBtn h3 {
     margin-top: 4px;
     margin-left: 15%;
     font-size: 22px;
 }

 input {
     width: 100%;
     height: 40px;
     font-size: large;
     margin-top: 5px;
     padding-top: 10px;
 }

 form {

     margin-right: 80px;
     margin-left: 10%;
 }

 /* .annullerBouton {
     margin-left: 27%;
 } */

 .modalBackground {
     width: 100%;
     height: 100%;
     /* background-color: rgba(146, 137, 137, 0.623); */
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     pointer-events: auto;
     cursor: default;
     z-index: 17;
     backdrop-filter: blur(2px);
 }