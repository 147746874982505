 /* Afficher la hoverContent au survol de la cellule */
 .nomLocationCell:hover .hoverContent {
     display: inline-flex;
     white-space: nowrap;
     /* color: #4F4F63; */
     color: #4F4F63;

     position: absolute;
     z-index: 99999999999999;


     /* Déplace la hoverContent vers le bas de la cellule avec un espacement de 10px */
 }

 /* Masquer la hoverContent par défaut */
 .nomLocationCell .hoverContent {
     white-space: nowrap;
     display: none;
 }

 .ligneHover {
     white-space: nowrap;
     background-color: rgb(201, 199, 199);
     border-radius: 20px;
     padding: 5px;
 }



 img {
     color: #4F4F63;
     width: 25px;
     cursor: pointer;

 }

 .copy-button {

     color: #030000;
     margin-left: 7px;
     margin-top: 10px;
     width: auto;
     height: 13px;
     cursor: pointer;
 }


 .infoLoc {
     position: absolute;
    
     width: 100%;
     padding-top: 18px;
     background-color: #eaeaeac8;
     color: #34343f;
     border: 0.5px solid rgb(210, 210, 210);
     border-radius: 7px;
     padding-left: 15px;
     margin-top: 3vh;
     overflow: hidden;
     /* word-wrap: break-word; */
     overflow-y: auto;
     height: 50vh;
     max-height: 50vh;
 }

 .contInfoLoc {
     position: relative;

 }

 .loc {
     position: fixed;
     flex: 1;
     margin-top: 5%;
     margin-left: 65vw;
     border-left: 0.7px solid rgb(201, 199, 199);

     /* Le premier élément prend autant d'espace que possible à gauche */

     display: block;
     height: 100%;
     width: 10%;
 }

 @media (max-width: 1307px) {
     .loc {
         display: none;
     }
 }

 .truncate {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }


 .centerLoc {
     display: flex;
     justify-content: center;
 }


 .loc-column-content {
     max-width: 15vw;
     height: 9vh;
     overflow: hidden;
     text-overflow: ellipsis;
 }

 .loc-column-content1 {

     white-space: nowrap;

 }

 .ui-datatable thead th {
     border-bottom: 1px solid #ddd;
     /* Couleur et style de la bordure */
 }

 /* Centre le contenu des cellules */
 .ui-datatable tbody tr td {
     text-align: center;
     vertical-align: middle;
 }

 .rescentCli {
     background-color: #dece93;

 }

 .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Ajoutez un gap entre les éléments pour plus d'espacement */
}

.gaucheStock {
    flex: 1;
    min-width: 300px;
}

.droiteStock {
    flex: 0 0 30%;
    min-width: 250px;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .flex {
        flex-direction: column;
    }
    .droiteStock {
        margin-left: 0;
        width: 100%;
    }
}
