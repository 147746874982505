 .colonne {
     display: flex;
     flex-direction: column;
     margin: 0px;
     /* Spécifie que les éléments doivent être alignés en colonne */

     /* Aligne les éléments horizontalement au centre de la colonne */
 }

 .ligne {
     position: relative;
     display: flex;
     /* Utilisez flexbox pour afficher les composants côte à côte */
     flex-wrap: wrap;

 }

 .box90 { 

     /* border: 1px solid rgb(251, 246, 246); */
     height: 50px;
     width: 30px;
     color: rgb(255, 255, 255);
     position: relative;
      
 }

 .box57 { 
/* 
     border: 1px solid rgb(251, 246, 246); */
     height: 70px;
     width: 50px;
     color: rgb(255, 255, 255);
     position: relative;
   
 }

 .box53 { 

     /* border: 1px solid rgb(251, 246, 246); */
     height: 70px;
     width: 70px;
     color: rgb(255, 255, 255);
     position: relative; 
 }

 .box54 {
    

     /* border: 1px solid rgb(251, 246, 246); */
     height: 46px;
     width: 55px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .box4 {
      
     /* border: 1px solid rgb(251, 246, 246); */
     height: 110px;
     width: 115px;
     color: rgb(255, 255, 255);
     position: relative; 
 }

 .box2 {
     
     /* border: 1px solid rgb(251, 246, 246); */
     height: 158px;
     width: 80px;
     color: rgb(255, 255, 255);
     position: relative;
   
 }

 .box6 {
  
     /* border: 1px solid rgb(251, 246, 246); */
     height: 70px;
     width: 160px;
     color: rgb(255, 255, 255);
     position: relative;
   
 }

 .box100{
   
    /* border: 1px solid rgb(251, 246, 246); */
    height: 110px;
    width: 110px;
    color: rgb(255, 255, 255);
    position: relative; 
}

 .carton {
    
     /* border: 1px solid rgb(251, 246, 246); */
     height: 100px;
     width: 50px;
     color: rgb(255, 255, 255);
     position: relative; 
 }