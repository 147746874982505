 /* Dans votre fichier de styles CSS ou via une balise style dans le composant React */
 .total {
     display: flex;
     justify-content: space-between;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
 }

 .gauche {
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
     height: 100vh;
 }

 .droite {
     font-family: 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;

     /* flex-grow: 1; */
 }

 /* Ajoutez ces styles à votre fichier CSS */
 .linkStyle {
     text-decoration: none;

 }

 .linkStyle:hover {
     background-color: #e0e0e0;
     /* Couleur de fond au survol */
     cursor: pointer;
 }

 .linkStyle:focus {
     outline: none;
     /* Supprime l'effet d'ombre par défaut lorsqu'il est focus */
 }

 /* Assurez-vous qu'aucun style par défaut n'altère la taille du texte */
 .MuiTypography-root {
     font-size: inherit !important;
 }

 .gaucheStock {
    width:95%;
    padding : 2px;
 
}

.droiteStock {
   
    width: 19%;
    overflow-y: auto;
    border-radius: 7px;
    border: 0.7px solid rgb(201, 199, 199);
    padding: 2%;
    margin-right : 1%;
    height: 87vh;
    max-height:87vh ;
    position: fixed;
    /* overflow: hidden;  */
    right: 0;
}
.custom-menu {
    z-index: 1001 !important; /* Assurez-vous que cette valeur est supérieure à celle des autres éléments */
}