 .toutStock {
     min-width: max-content;
     /* margin-left: 3px; */
 }



 .colonne {
     display: flex;
     flex-direction: column;
     margin: 0px;
     /* Spécifie que les éléments doivent être alignés en colonne */

     /* Aligne les éléments horizontalement au centre de la colonne */
 }

 .ligne {
     position: relative;
     display: flex;
     /* Utilisez flexRbox pour afficher les composants côte à côte */
     flex-wrap: wrap;

 }

 .moyenBox {
     position: absolute;
/* 
     border: 1px solid rgb(251, 246, 246); */
     height: 100px;
     width: 50px;
     color: rgb(255, 255, 255);
     position: relative; 
 }

 .boxL {
     position: absolute;
/* 
     border: 1px solid rgb(251, 246, 246); */
     height: 100px;
     width: 30px;
     color: rgb(255, 255, 255);
     position: relative; 
 }

 .Rbox {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 50px;
     width: 50px;
     color: rgb(255, 255, 255);
     position: relative; 
 }


 .Rbox3 {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 50px;
     width: 40px;
     color: rgb(255, 255, 255);
     position: relative; 
 }

 .Rbox2 {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 50px;
     width: 100px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .box97 {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 100px;
     width: 50px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .box68 {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 50px;
     width: 83px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .Rbox4 {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 153px;
     width: 50px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .miniBox {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     width: 25px;
     height: 50px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .Rbox7 {
     position: absolute;

     /* border: 1px solid rgb(251, 246, 246); */
     height: 40px;
     width: 40px;
     color: rgb(255, 255, 255);
     position: relative;
 
 }

 .Rbox8 {
     position: absolute;
     /* border: 1px solid rgb(251, 246, 246); */
     height: 50px;
     width: 85px;
     color: rgb(255, 255, 255);
     position: relative; 
 }
 