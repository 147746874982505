 .modalBackgroundRgl {

     width: 100%;
     height: 100%;
     background-color: rgba(112, 103, 103, 0.623);
     position: fixed;

     display: flex;
     justify-content: center;
     align-items: center;
     pointer-events: auto;
     cursor: default;
     z-index: 17;
     margin-left: -35.5vh;
     margin-top: -2.8vh;




 }

 .modalContainerRgl {


     width: 60%;
     border-radius: 12px;
     background-color: white;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 27px;
     flex-direction: column;
     padding: 25px;
     z-index: 9;

     /* Ajouter ces styles pour permettre le défilement vertical */
     max-height: 80vh;
     /* Par exemple, ajustez la valeur selon vos besoins */
     overflow-y: auto;
     /* Activer le défilement vertical si nécessaire */
 }

 .parent-span-rgl {
     display: flex;
     justify-content: space-between;
     width: 100%;
     padding: 10px;

 }

 .left-span-rgl {

     flex: 1;
     /* margin-left: -6vh; */
     /* Le premier élément prend autant d'espace que possible à gauche */
 }

 .right-span-rgl {
     margin-top: -100px;
     flex: 1;
     margin-left: 10vh;
 }