.background {
    background-color: #eaeaea;
    /* margin-top: 50px; */
    border-radius: 8px;

    font-size: 25px;
    font-weight: 400;
    position: relative;
    border: 0.3px solid rgb(201, 199, 199);
    padding: 20px;
    min-width: max-content;
}

 .bgMobile {


     background-color: #6c6b6b47;
     /* margin-top: 50px; */
     border-radius: 8px;
     ;
     font-size: 25px;
     font-weight: 400;
     border: 0.3px solid rgb(201, 199, 199);
     padding: 20px;
     width: 100%;
     min-width: 100%;
     /* min-width: max-content; */
 }



 .colonne {
     display: flex;
     flex-direction: column;
     /* Spécifie que les éléments doivent être alignés en colonne */

     /* Aligne les éléments horizontalement au centre de la colonne */
 }

 .ligne {
     position: relative;
     display: flex;
     /* Utilisez flexbox pour afficher les composants côte à côte */
     flex-wrap: wrap;

 }
 .stockRetard {
    border: 2px solid #52505059;
    color:#52505059;

}
 .jaune {
     text-align: center;
     /* background: linear-gradient(to bottom, #dfa003f6, #eeb31dd0); */
     background: linear-gradient(to bottom, #d67a02bb, #d69a02af);
     color:#fdfdfd;
     border: 1px solid #d67a02e3;
     border-radius: 3%;

     margin: 1px;
 }
 .vert1  {
     text-align: center;
      /* background: linear-gradient(to bottom, #1a7d9663, #46b3ce6b); 
    color:#fdfdfd;  
     border: 1px solid #0988a838;    */
     border-radius: 3%;
     margin: 1px;
 }

 .orange {
     text-align: center;
     align-content: center;
     border-radius: 5%;
     background: linear-gradient(to bottom, #010f16e8, #010e14be);
     color: #fffffff3;
     border: 1px solid #000a0f;
     margin: 0.1px;
 }



 .contenneurP {
     position: absolute;
     margin-top: 1vh;
     /* border: 1px solid rgb(251, 246, 246); */
     height: 13vh;
     width: 8vh;

     position: relative;
     /* Position relative pour ajuster la position */
     /* transform: rotate(90deg);
    /* Incline l'élément de 70 degrés par rapport à l'horizontale */
     /* transform-origin: top left; */
     /* Point d'origine de la rotation, ajustez selon vos besoins */
     /* left: 0;
    
    top: 0;   */

 }

 .contenneurG {

     /* border: 1px solid rgb(251, 246, 246); */
     height: 24vh;
     width: 8vh;

 }

 .contenneurPr {
     position: absolute;
     margin-top: 1vh;
     /* border: 1px solid rgb(251, 246, 246); */
     width: 13vh;
     height: 8vh;

     position: relative;
 }



 
 

 .Icon {

     left: 0;
     border-radius: 10%;
 }






 .containerBox {
     /* width: 100%; */
     padding: 2%;
     /* flex: 1; */


 }


 .titre {
     text-align: center;
     color: #000006d6;
     text-transform: uppercase;
     font-size: 27px;

     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
 }

 .titre1 {
     text-align: center;
     color: #4F4F63;
     text-decoration: underline;

     font-size: 20px;
     /* Transforme le texte en majuscules */
 }

 .highlight-red {
     font-weight: 700;
     border: 3px solid #f3390b;
     /* Couleur de la bordure */
     background: linear-gradient(to bottom, #d42929, #ee4807);
     /* Couleur du dégradé */
     color: white;
     /* Couleur du texte */
 }




 .flex {
     display: grid;
     grid-template-columns: 1fr 20%;
 }



 .gaucheStock>div>* {
     margin-bottom: 10px;
     /* Ajoutez la marge que vous souhaitez entre les éléments */
 }

 .zoom-outCont {
     margin-top: -50vh;
     transform: scale(0.48);
     /* ou tout autre facteur de zoom souhaité */
     transform-origin: 0 0;
     /* Point d'origine pour le zoom, ajustez si nécessaire */
     /* min-width: 100vw;
     width: 100vw; */
 }

 .zoom-outEtg {
     margin-top: -43vh;
     background-color: #dd0917d7;
     margin-left: 25vw;
     transform: scale(0.45);
     /* ou tout autre facteur de zoom souhaité */
     transform-origin: 0 0;
     /* Point d'origine pour le zoom, ajustez si nécessaire */
     min-width: max-content;
     width: 200vw;

 }

 