 .modalBackground {

     width: 100%;
     height: 100%;
     background-color: rgba(112, 103, 103, 0.623);
     position: fixed;

     display: flex;
     justify-content: center;
     align-items: center;
     pointer-events: auto;
     cursor: default;
     z-index: 17;
     margin-left: -35.5vh;
     margin-top: -2.8vh;

 }




 .modalContainerLoc {

     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif;
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     width: 60%;

     border-radius: 12px;
     background-color: white;
     box-shadow: 24px;
     padding: 2%;
     overflow-y: auto;
     z-index: 1000;
     height: min-content;
     max-height: 80vh;
     /* Par exemple, ajustez la valeur selon vos besoins */

 }

 @media screen and (max-width: 1607px) {
     .modalContainerLoc {

         width: 80%;


     }


 }








 .titreEtBtnLoc {
     text-align: center;
     /* Centre horizontalement le contenu */
 }

 .titreEtBtnLoc h3 {
     margin-top: 4px;
     font-size: 22px;
 }




 input {
     position: center;
     width: 100%;

     height: 40px;
     font-size: large;
     margin-top: 5px;
     padding-top: 10px;
 }

 form {
     margin-right: 80px;
     margin-left: 10%;
 }


 .button {
     margin-left: 40%;
 }

 /* 
 .MuiButton-containedPrimary.myBouton {
     margin-left: 17%;
 }
 */
 .annullerBouton {


     margin-left: 15%;
 }


 .indication {
     color: rgb(113, 110, 110);
 }

 .parent-span {
     display: flex;
     justify-content: space-between;
     width: 100%;
     padding: 10px;

 }

 .left-span {
     flex: 1;
     margin-left: -6vh;
     /* Le premier élément prend autant d'espace que possible à gauche */
 }

 .right-span {
     flex: 1;
     margin-left: 4vh;
 }

 .calcul {
     padding: 18px;
     background-color: rgba(244, 244, 241, 0.813);
     border: 0.5px solid rgb(202, 198, 198);
     border-radius: 10px;
     padding-left: 15px;
     margin-top: 3vh;

 }